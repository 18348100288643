// @flow

import React from 'react';
import {graphql} from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import Container from 'atomize/dist/atoms/grid/Container';
import Row from 'atomize/dist/atoms/grid/Row';
import Col from 'atomize/dist/atoms/grid/Col';
import Text from 'atomize/dist/atoms/text/Text';
import Div from 'atomize/dist/atoms/div/Div';

import HtmlHead from '../components/HtmlHead';
import PostCard from '../components/blog/PostCard';

export const query = graphql`
query BlogQuery {
  allGraphCmsBlogPost {
    edges {
      node {
        createdAt
        excerpt
        slug
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(maxWidth: 600, maxHeight: 600, layout: FLUID)
            }
          }
        }
      }
    }
  }
}
`;


export default function BlogView({data}: Object) {



  return (
    <Container>
      <HtmlHead title='Blogas | bukitesveiki.lt' />

      <Text textColor='black' textSize='display1' tag='h1' textAlign='center'>
        Blogas
      </Text>

      <Row p={{t: '2rem', b: '2rem'}}>
        {
          data.allGraphCmsBlogPost.edges.map(({node}) => {

            const {slug, image, title, excerpt, createdAt } = node;
            const imgData = image.localFile.childImageSharp.gatsbyImageData;

            return (
              <Col size={{xs: 12, md: 6, lg: 4}} key={`blog-col-${slug}`}>
                <PostCard slug={slug} excerpt={excerpt} title={title}>
                    <GatsbyImage image={imgData} alt={`${slug} paveikslėlis`} />
                </PostCard>
              </Col>
            );
          })
        }
      </Row>
    </Container>
  );
}
