// @flow

import React from 'react';
import Row from 'atomize/dist/atoms/grid/Row';
import Text from 'atomize/dist/atoms/text/Text';
import Div from 'atomize/dist/atoms/div/Div';

import MenuLink from '../MenuLink';

type Props = {
  +excerpt: string,
  +slug: string,
  +title: string,
  +dateLbl: string,
  +children: any
}

export default function PostCard(props: Props) {

  const {excerpt, slug, title, dateLbl, children} = props;

  return (
    <Div p={{t: '1rem'}}>
      <MenuLink to={`blogas/${slug}`}>

        {children}
        <Text tag='h2' textSize='title' p={{t: '1rem', b: '0.5rem'}}>{title}</Text>
        <Text textSize='caption' p={{b: '1rem'}} textWeight='400'>
          {excerpt}
        </Text>

      </MenuLink>
    </Div>
  );
}
